import React, { useState, useEffect } from 'react';
import './OurStory.css';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const OurStory = () => {
  const [storyText, setStoryText] = useState('');
  const { ref, inView } = useInView({
    threshold: 0.2 // Trigger animation when 70% of the element is visible
  });

  useEffect(() => {
    // Fetch data from API
    const fetchStory = async () => {
      try {
        const response = await fetch('https://shristicooperative.com/api/site-info');
        const data = await response.json();
        setStoryText(data.our_story_text);
      } catch (error) {
        console.error('Error fetching site info:', error);
      }
    };

    fetchStory();
  }, []);

  return (
    <div className="our-story" ref={ref} id="ourstory">
      <motion.h2
        className="section-title"
        initial={{ opacity: 0, y: -50 }}
        animate={inView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 1 }}
      >
        Our Story
      </motion.h2>
      <div className="our-story-content">
        <motion.div
          className="story-text"
          initial={{ opacity: 0, y: -50 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1 }}
        >
          <p>{storyText}</p>
        </motion.div>
        <motion.div
          className="story-carousel"
          initial={{ opacity: 0, x: 50 }}
          animate={inView ? { opacity: 1, x: 0 } : {}}
          transition={{ duration: 1 }}
        >
          <Carousel autoPlay interval={3000} infiniteLoop showThumbs={false}>
            <div>
              <img className='cimage' src="/images/1.png" alt="Story Image 1" />
            </div>
            <div>
              <img className='cimage' src="/images/2.png" alt="Story Image 2" />
            </div>
           
            {/* Add more images as needed */}
          </Carousel>
        </motion.div>
      </div>
    </div>
  );
};

export default OurStory;
