import React from 'react'
import { Link } from 'react-router-dom'
import './AdminNavbar.css'
const AdminNavbar = () => {
    return (
        <div className='admin-nav'>
            <Link to="/texts">
                Text Modification
            </Link>

            <Link to="/admin">
                Products
            </Link>

            <Link to="/gallery">
                Gallery
            </Link>
        </div>

    )
}

export default AdminNavbar