import React from 'react';
import { motion } from 'framer-motion';
import './MissionVision.css';

const MissionVision = () => {
  const fadeInVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  };

  return (
    <div className="mission-vision-container">
      <motion.section
        className="mission-section"
        initial="hidden"
        whileInView="visible"
        variants={fadeInVariants}
        viewport={{ once: true, amount: 0.2 }}
      >
        <div className="image-container">
          <img src="/images/3.jpeg" alt="Our Mission" />
        </div>
        <div className="text-container">
          <h2>Our Mission</h2>
          <p>
            Our mission is to innovate and lead the market by providing top-notch solutions that
            improve the lives of our customers. We strive to create a positive impact through
            our work, ensuring that every product we deliver meets the highest standards of
            quality and efficiency.
          </p>
        </div>
      </motion.section>

      <motion.section
        className="vision-section"
        initial="hidden"
        whileInView="visible"
        variants={fadeInVariants}
        viewport={{ once: true, amount: 0.2 }}
      >
        <div className="image-container">
          <img src="/images/4.jpeg" alt="Our Vision" />
        </div>
        <div className="text-container">
          <h2>Our Vision</h2>
          <p>
            Our vision is to be a global leader in our industry, recognized for our commitment
            to excellence and our ability to adapt to the changing needs of the market. We
            aim to inspire and empower our employees, partners, and customers to achieve their
            full potential.
          </p>
        </div>
      </motion.section>
    </div>
  );
};

export default MissionVision;
