import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './Categories.css';

const Categories = () => {
    const { ref, inView } = useInView({
        threshold: 0.2 // Trigger animation when 20% of the element is visible
    });

    return (
        <section className='categories' ref={ref} id='categories'>
            <h2 className='categories-heading'>Our Categories</h2>
            <div className='category-container'>
                <motion.div className="category-card" initial={{ opacity: 0, y: -50 }} animate={inView ? { opacity: 1, y: 0 } : {}} transition={{ duration: 1 }}>
                    <Link to="/homedecor">
                        <img src="/categories/homedecor.gif" alt="HomeDecor" />
                        <h3>HomeDecor</h3>
                    </Link>
                </motion.div>

                <motion.div className="category-card" initial={{ opacity: 0, y: -50 }} animate={inView ? { opacity: 1, y: 0 } : {}} transition={{ duration: 1 }}>
                    <Link to="/jewellery">
                        <img src="/categories/jewellery.gif" alt="Jewellery" />
                        <h3>Jewellery</h3>
                    </Link>
                </motion.div>

                <motion.div className="category-card" initial={{ opacity: 0, y: -50 }} animate={inView ? { opacity: 1, y: 0 } : {}} transition={{ duration: 1 }}>
                    <Link to="/fooditems">
                        <img src="/categories/food-items.gif" alt="FoodItems" />
                        <h3>Food Items</h3>
                    </Link>
                </motion.div>

                <motion.div className="category-card" initial={{ opacity: 0, y: -50 }} animate={inView ? { opacity: 1, y: 0 } : {}} transition={{ duration: 1 }}>
                    <Link to="/fashion">
                        <img src="/categories/fashion.gif" alt="Clothes" />
                        <h3>Fashion</h3>
                    </Link>
                </motion.div>

                <motion.div className="category-card" initial={{ opacity: 0, y: -50 }} animate={inView ? { opacity: 1, y: 0 } : {}} transition={{ duration: 1 }}>
                    <Link to="/homedecor">
                        <img src="/categories/gifts.gif" alt="Gifts" />
                        <h3>Customized Gifts</h3>
                    </Link>
                </motion.div>

                <motion.div className="category-card" initial={{ opacity: 0, y: -50 }} animate={inView ? { opacity: 1, y: 0 } : {}} transition={{ duration: 1 }}>
                    <Link to="/homedecor">
                        <img src="/categories/handicraft.gif" alt="Handicraft" />
                        <h3>Handicraft</h3>
                    </Link>
                </motion.div>

                <motion.div className="category-card" initial={{ opacity: 0, y: -50 }} animate={inView ? { opacity: 1, y: 0 } : {}} transition={{ duration: 1 }}>
                    <Link to="/homedecor">
                        <img src="/categories/eco-products.gif" alt="Eco Products" />
                        <h3>Eco Products</h3>
                    </Link>
                </motion.div>
            </div>
        </section>
    );
};

export default Categories;
