import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './About.css';

const About = () => {
  const [aboutText, setAboutText] = useState('');

  useEffect(() => {
    // Fetch data from API
    const fetchAboutInfo = async () => {
      try {
        const response = await fetch('https://shristicooperative.com/api/site-info');
        const data = await response.json();
        setAboutText(data.about_text);
      } catch (error) {
        console.error('Error fetching site info:', error);
      }
    };

    fetchAboutInfo();
  }, []);

  return (
    <div className="about-container">
      <motion.div className="about-section" initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 2 }}>
        <div className="about-text">
          <h1>About Us</h1>
          <p>{aboutText}</p>
        </div>
        <div className="about-image">
          <img src="/images/1.png" alt="About Madyantika" />
        </div>
      </motion.div>
      <motion.div className="collection-carousel" initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 2 }}>
        <h2>Our Work</h2>
        <Carousel showArrows={true} autoPlay={true} infiniteLoop={true}>
          <div>
            <img src="/work/1.jpeg" alt="Collections Image 1" />
          </div>
          <div>
            <img src="/work/2.jpeg" alt="Collections Image 2" />
          </div>
          <div>
            <img src="/work/3.jpeg" alt="Collections Image 3" />
          </div>
          <div>
            <img src="/work/4.jpeg" alt="Collections Image 4" />
          </div>
        </Carousel>
      </motion.div>
      <motion.div className="team-section" initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 2 }}>
        <h2>Our Team</h2>
        <div className="team-members">
          <div className="team-member">
            <img src="https://avatar.iran.liara.run/public/89" alt="Team Member 1" />
            <h3>Parmita Paul</h3>
            <p>CEO</p>
          </div>
          <div className="team-member">
            <img src="https://avatar.iran.liara.run/public/89" alt="Team Member 2" />
            <h3>Kalyani Paul</h3>
            <p>CTO</p>
          </div>
          <div className="team-member">
            <img src="https://avatar.iran.liara.run/public/89" alt="Team Member 3" />
            <h3>Himadri Das</h3>
            <p>COO</p>
          </div>
        </div>
      </motion.div>
      <motion.div className="additional-sections" initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 2 }}>
        <section>
          <h2>Our Collections</h2>
          <p>Details about our work...</p>
        </section>
        <section>
          <h2>About Our Customers</h2>
          <p>Details about our customers...</p>
        </section>
      </motion.div>

     
    </div>
  );
};

export default About;
