import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './ProductDetails.css';

const ProductDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await fetch(`https://shristicooperative.com/api/v1/products/${id}`);
        const data = await response.json();
        setProduct(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching product details:', error);
      }
    };

    fetchProductDetails();
  }, [id]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!product) {
    return <p>Product not found</p>;
  }

  return (
    <div className="product-details">
      <div className="product-details-container">
        <div className="product-image-container">
          <img src={`https://shristicooperative.com/storage/app/public/products/${product.image}`} alt={product.name} className="product-image" />
        </div>
        <div className="product-info-container">
          <h1 className="product-name">{product.name}</h1>
          <p className="product-price">Price: ₹{product.price}</p>
          <p className="product-description">{product.description}</p>
          <p className="product-qty">Minimum Order Quantity: {product.min_order_qty}</p>
          <a
            href={`https://api.whatsapp.com/send?phone=918399834759&text=I want to enquire about product_id: ${product.id}, name: ${product.name} from madyantika`}
            className="button-22"
          >
            Do Enquiry
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
