import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/navbar/Navbar';
import HomePage from './pages/homepage/HomePage';
import HomeDecor from './pages/homedecor/HomeDecor';
import Jewellery from './pages/jewellery/Jewellery';
import FoodItems from './pages/fooditems/FoodItems';
import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import ProductPage from './admin/productscrud/ProductPage';
import Fashion from './pages/fashion/Fashion';
import AdminPage from './admin/AdminPage';
import ProductDetails from './pages/productdetails/ProductDetails';
import Categories from './components/categories/Categories';
import ScrollToTop from './components/navbar/ScrollToTop';

const App = () => {
  return (
    <Router>
      <ScrollToTop/>
      <Navbar/>
      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="/homedecor" element={<HomeDecor/>} />
        <Route path="/jewellery" element={<Jewellery/>} />
        <Route path="/fooditems" element={<FoodItems/>} />
        <Route path="/fashion" element={<Fashion/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/categories" element={<Categories/>} />


        <Route path="/admin" element={<ProductPage/>} />
        <Route path="/texts" element={<AdminPage/>} />
        <Route path="/product/:id" element={<ProductDetails/>} />} />
      </Routes>
    </Router>
  )
}

export default App