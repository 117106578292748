import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import './HeroSection.css';

const HeroSection = () => {
    return (
        <section className='homepage' id='home'>
            <motion.div className='header' initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1.5}}>
                <h1>Welcome to<span> Madyantika</span></h1>
                
            </motion.div>

            <motion.p className="header-description" initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1.5 }}>
           Multi-product store for home grown products, Made in India.
            </motion.p>

            <a href='#categories' class="scroll-down">
  <div class="chevron"></div>
  <div class="chevron"></div>
  <div class="chevron"></div>
  <span class="text">View Categories</span>
</a>
        </section>
    );
};

export default HeroSection;
