import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FaStar } from 'react-icons/fa';
import './Testimonials.css';

const testimonials = [
  {
    name: 'John Doe',
    message: 'This is the best service I have ever used. Highly recommended!',
    image: 'https://avatar.iran.liara.run/public/79',
    rating: 5
  },
  {
    name: 'Jane Smith',
    message: 'A wonderful experience from start to finish. Will use again!',
    image: 'https://avatar.iran.liara.run/public/89',
    rating: 4
  },
  {
    name: 'Alice Johnson',
    message: 'Exceeded my expectations in every way. Fantastic!',
    image: 'https://avatar.iran.liara.run/public/68',
    rating: 5
  }
];

const Testimonials = () => {
  return (
    <div className="testimonials-container">
      <h2>What Our Clients Say</h2>
      <Carousel
        showArrows={true}
        autoPlay={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        interval={5000}
        transitionTime={800}
        className="testimonial-carousel"
      >
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-card">
            <img src={testimonial.image} alt={testimonial.name} className="testimonial-image" />
            <div className="testimonial-content">
              <h3 className="testimonial-name">{testimonial.name}</h3>
              <p className="testimonial-message">{testimonial.message}</p>
              <div className="testimonial-rating">
                {[...Array(testimonial.rating)].map((_, i) => (
                  <FaStar key={i} className="star-icon" />
                ))}
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Testimonials;
