import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './FoodItems.css';
import { motion } from 'framer-motion';
import { FaCartPlus } from 'react-icons/fa';

const FoodItems = () => {
  const [foodItems, setFoodItems] = useState([]);
  const [whatsappLink, setWhatsappLink] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch site info to get WhatsApp link
    const fetchSiteInfo = async () => {
      try {
        const response = await fetch('https://shristicooperative.com/api/site-info');
        const data = await response.json();
        setWhatsappLink(data.whatsapp_business_link);
      } catch (error) {
        console.error('Error fetching site info:', error);
      }
    };

    // Fetch food items data
    const fetchFoodItems = async () => {
      try {
        const response = await fetch('https://shristicooperative.com/api/v1/products/category/fooditems');
        const data = await response.json();
        setFoodItems(data);
      } catch (error) {
        console.error('Error fetching food items:', error);
      }
    };

    fetchSiteInfo();
    fetchFoodItems();
  }, []);

  // Function to generate WhatsApp URL
  const generateWhatsappLink = (productId, productName) => {
    const message = `I want to enquire about product_id: ${productId}, name: ${productName} from Madyantika.`;
    return `${whatsappLink}text=${encodeURIComponent(message)}`;
  };

  return (
    <div className='food-items'>
      <h1>Food Items</h1>
      <motion.div className="food-items-container" initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1 }}>
        {foodItems.length > 0 ? (
          foodItems.map((item, index) => (
            <motion.div key={index} className="food-item-card" initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1, delay: index * 0.1 }}>
              <img src={`https://shristicooperative.com/storage/app/public/products/${item.image}`} alt={item.name} className="food-item-image" />
              <div className="food-item-info">
                <h3>{item.name}</h3>
                <p>₹{item.price} / item</p>
                <p className='qty'>Minimum Order Quantity: {item.min_order_qty}</p>
              </div>
              <button 
                className="button-44" 
                onClick={() => window.location.href = generateWhatsappLink(item.id, item.name)}
              >
                <FaCartPlus /> Do Enquiry
              </button>
              <button 
                className="button-43" 
                onClick={() => navigate(`/product/${item.id}`)}
              >
                <FaCartPlus /> View Details
              </button>
            </motion.div>
          ))
        ) : (
          <p>Loading items...</p>
        )}
      </motion.div>
    </div>
  );
}

export default FoodItems;
