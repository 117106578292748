import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './MessageFromFounders.css';

const MessageFromFounders = () => {
  const founders = [
    {
      name: 'Parmita Paul',
      title: 'CEO',
      message: 'Our mission is to innovate and lead the market.Our mission is to innovate and lead the market.Our mission is to innovate and lead the market.',
      image: 'https://avatar.iran.liara.run/public/39'
    },
    {
      name: 'Kalyani Paul',
      title: 'CTO',
      message: 'We focus on delivering high-quality tech solutions.Our mission is to innovate and lead the market.Our mission is to innovate and lead the market.',
      image: 'https://avatar.iran.liara.run/public/18'
    },
    {
      name: 'Himadri Das',
      title: 'COO',
      message: 'Customer satisfaction is our top priority.Our mission is to innovate and lead the market.Our mission is to innovate and lead the market.',
      image: 'https://avatar.iran.liara.run/public/44'
    }
  ];

  return (
    <div className="founders-container">
      <h2>Message From Our Founders</h2>
      <Carousel showArrows={true} autoPlay={true} infiniteLoop={true} className="message-carousel">
        {founders.map((founder, index) => (
          <div key={index} className="founder-card">
            <img src={founder.image} alt={founder.name} />
            <div className="founder-info">
              <h3>{founder.name}</h3>
              <h4>{founder.title}</h4>
              <p>{founder.message}</p>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default MessageFromFounders;
