import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import './Navbar.css';

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  useEffect(() => {
    // Close mobile menu when route changes
    closeMobileMenu();
  }, [location]);

  return (
    <nav className="navbar">
      <Link to="/" className="brand" onClick={closeMobileMenu}>
        <h1>Madyantika</h1>
      </Link>

      <div className={`menu ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
        <Link to="/" onClick={closeMobileMenu} className={location.pathname === '/' ? 'active' : ''}>
          Home
        </Link>

        <HashLink smooth to="/#ourstory" onClick={closeMobileMenu} className={location.hash === '#ourstory' ? 'active' : ''}>
          Our Story
        </HashLink>

        <Link to="/categories" onClick={closeMobileMenu} className={location.pathname === '/categories' ? 'active' : ''}>
          Categories
        </Link>

        <Link to="/about" onClick={closeMobileMenu} className={location.pathname === '/about' ? 'active' : ''}>
          About Us
        </Link>

        <Link to="/contact" onClick={closeMobileMenu} className={location.pathname === '/contact' ? 'active' : ''}>
          <button className="book-meeting1">Contact Us</button>
        </Link>
      </div>

      <Link to="/contact" onClick={closeMobileMenu} className={location.pathname === '/contact' ? 'active' : ''}>
        <button className="book-meeting">Contact Us</button>
      </Link>

      <div className={`mobile-menu-icon ${isMobileMenuOpen ? 'open' : ''}`} onClick={toggleMobileMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
    </nav>
  );
};

export default Navbar;
