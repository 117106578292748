import React, { useState, useEffect } from 'react';
import './ProductPage.css';
import LogoutButton from './LogoutButton';
import AdminNavbar from '../navbar/AdminNavbar';

const ProductPage = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    price: '',
    category: '',
    description: '',
    min_order_qty: '',
    image: null
  });
  const [currentProduct, setCurrentProduct] = useState(null);
  const [search, setSearch] = useState({
    name: '',
    category: ''
  });
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loginData, setLoginData] = useState({
    username: '',
    password: ''
  });

  useEffect(() => {
    const adminId = localStorage.getItem('madyantika_admin_id');
    if (adminId) {
      setIsLoggedIn(true);
      fetchProducts();
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      filterProducts();
    }
  }, [products, search, isLoggedIn]);

  const fetchProducts = async () => {
    const response = await fetch('https://shristicooperative.com/api/v1/products');
    const data = await response.json();
    setProducts(data);
  };

  const filterProducts = () => {
    const { name, category } = search;
    const filtered = products.filter(product =>
      (name ? product.name.toLowerCase().includes(name.toLowerCase()) : true) &&
      (category ? product.category.toLowerCase().includes(category.toLowerCase()) : true)
    );
    setFilteredProducts(filtered);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleImageChange = (e) => {
    setFormData(prevData => ({ ...prevData, image: e.target.files[0] }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = new FormData();
    Object.keys(formData).forEach(key => {
      form.append(key, formData[key]);
    });

    const url = currentProduct ? `https://shristicooperative.com/api/v1/products/${currentProduct.id}` : 'https://shristicooperative.com/api/v1/products';
    const method = currentProduct ? 'PUT' : 'POST';

    await fetch(url, {
      method,
      body: form
    });

    setShowForm(false);
    setFormData({
      name: '',
      price: '',
      category: '',
      description: '',
      min_order_qty: '',
      image: null
    });
    fetchProducts();
  };

  const handleEdit = (product) => {
    setCurrentProduct(product);
    setFormData({
      name: product.name,
      price: product.price,
      category: product.category,
      description: product.description,
      min_order_qty: product.min_order_qty,
      image: null
    });
    setShowForm(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      await fetch(`https://shristicooperative.com/api/v1/products/${id}`, { method: 'DELETE' });
      fetchProducts();
    }
  };

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearch(prevSearch => ({ ...prevSearch, [name]: value }));
  };

  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLoginData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();

    const response = await fetch('https://shristicooperative.com/api/admin/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(loginData)
    });

    const data = await response.json();
    if (data.id) {
      localStorage.setItem('madyantika_admin_id', data.id);
      setIsLoggedIn(true);
      fetchProducts();
    } else {
      alert('Login failed. Please check your credentials and try again.');
    }
  };

  if (!isLoggedIn) {
    return (
      <div className="login-form">
        <h2>Admin Login</h2>
        <form onSubmit={handleLoginSubmit}>
          <label>
            Username:
            <input type="text" name="username" value={loginData.username} onChange={handleLoginChange} required />
          </label>
          <label>
            Password:
            <input type="password" name="password" value={loginData.password} onChange={handleLoginChange} required />
          </label>
          <button type="submit">Login</button>
        </form>
      </div>
    );
  }

  return (
    <div className="product-page">
      <AdminNavbar />
      <button onClick={() => setShowForm(true)} className="add-product-btn">Add New Product</button>
      <LogoutButton />
      <div className="search-bar">
        <label>
          Name:
          <input
            type="text"
            name="name"
            value={search.name}
            onChange={handleSearchChange}
            placeholder="Search by name"
          />
        </label>
        <label>
          Category:
          <input
            type="text"
            name="category"
            value={search.category}
            onChange={handleSearchChange}
            placeholder="Search by category"
          />
        </label>
      </div>

      {showForm && (
        <div className="form-overlay">
          <div className="form-container">
            <button className="close-btn" onClick={() => setShowForm(false)}>X</button>
            <h2>{currentProduct ? 'Edit Product' : 'Add New Product'}</h2>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              <label>
                Name:
                <input type="text" name="name" value={formData.name} onChange={handleInputChange} required />
              </label>
              <label>
                Price:
                <input type="number" name="price" value={formData.price} onChange={handleInputChange} required />
              </label>
              <label>
                Category:
                
                <select name="category" value={formData.category} onChange={handleInputChange}  required  >
                  <option value="jewellery">Jewellery</option>
                  <option value="homedecor">HomeDecor</option>
                  <option value="fooditems">FoodItems</option>
                  <option value="fashion">Fashion</option>
                  <option value="gifts">Gifts</option>
                  <option value="handicraft">Handicraft</option>
                  <option value="ecoproducts">Eco Products</option>
                 
                  
                </select>
              </label>
              <label>
                Description:
                <textarea name="description" value={formData.description} onChange={handleInputChange} />
              </label>
              <label>
                Minimum Order Quantity:
                <input type="number" name="min_order_qty" value={formData.min_order_qty} onChange={handleInputChange} required />
              </label>
              <label>
                Image:
                <input type="file" name="image" accept="image/*" onChange={handleImageChange} />
              </label>
              <button type="submit">{currentProduct ? 'Update Product' : 'Add Product'}</button>
            </form>
          </div>
        </div>
      )}

      <div className="product-list">
        {filteredProducts.map(product => (
          <div key={product.id} className="product-item">
            <img src={`https://shristicooperative.com/storage/app/public/products/${product.image}`} alt={product.name} className="product-image" />
            <div className="product-info">
              <h3>{product.name}</h3>
              <p>Price: ${product.price}</p>
              <p>Category: {product.category}</p>
              <p>Description: {product.description}</p>
              <p>Min Order Quantity: {product.min_order_qty}</p>
              <button onClick={() => handleEdit(product)} className="edit-btn">Edit</button>
              <button onClick={() => handleDelete(product.id)} className="delete-btn">Delete</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductPage;
