import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './AdminPage.css'; // Import your CSS for styling
import AdminNavbar from './navbar/AdminNavbar';

const AdminPage = () => {
  const [siteInfo, setSiteInfo] = useState({
    our_story_text: '',
    about_text: '',
    whatsapp_business_link: '',
  });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const adminId = localStorage.getItem('madyantika_admin_id');
    if (!adminId) {
      navigate('/admin'); // Redirect to login if admin_id is not found
    } else {
      fetchSiteInfo();
    }
  }, [navigate]);

  const fetchSiteInfo = async () => {
    try {
      const response = await fetch('https://shristicooperative.com/api/site-info');
      const data = await response.json();
      setSiteInfo(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching site info:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSiteInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handleSaveChanges = async () => {
    try {
      const response = await fetch('https://shristicooperative.com/api/site-info', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(siteInfo),
      });
      if (response.ok) {
        alert('Changes saved successfully!');
      } else {
        alert('Failed to save changes.');
      }
    } catch (error) {
      console.error('Error saving changes:', error);
      alert('Error saving changes.');
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="admin-page">
        <AdminNavbar/>
      <h1>Admin Page</h1>
      <form className="admin-form">
        <label>
          Our Story Text:
          <textarea
            name="our_story_text"
            value={siteInfo.our_story_text}
            onChange={handleInputChange}
            rows="6"
          />
        </label>
        <label>
          About Text:
          <textarea
            name="about_text"
            value={siteInfo.about_text}
            onChange={handleInputChange}
            rows="6"
          />
        </label>
        <label>
          WhatsApp Business Link:
          <input
            type="text"
            name="whatsapp_business_link"
            value={siteInfo.whatsapp_business_link}
            onChange={handleInputChange}
          />
        </label>
        <button type="button" onClick={handleSaveChanges}>
          Save Changes
        </button>
      </form>
    </div>
  );
};

export default AdminPage;
