import React from 'react';
import { FaInstagram, FaFacebookF } from 'react-icons/fa';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-links">
          <ul>
            <li><a href="/about">About Us</a></li>
            <li><a href="/contact">Contact</a></li>
            <li><a href="/refund-policy">Refund Policy</a></li>
            <li><a href="/privacy-policy">Privacy Policy</a></li>
            <li><a href="/terms-and-conditions">Terms and Conditions</a></li>
          </ul>
        </div>
        <div className="footer-info">
          <p>&copy; 2024 Madyantika. All rights reserved.</p>
          <p>Email: <a href="mailto:madyantika05@gmail.com">Madyantika05@gmail.com</a></p>
          <p>Phone Number: <a href="tel:+919394213294">9394213294</a></p>
          <div className="social-icons">
            <p>Follow us on Social Media</p>
            <div className="social-icon">
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <FaFacebookF />
            </a>
            </div>
           
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
