import React, { useState, useEffect } from 'react';
import './Jewellery.css';
import { motion } from 'framer-motion';
import { FaCartPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const Jewellery = () => {
  const [decorItems, setDecorItems] = useState([]);
  const [whatsappLink, setWhatsappLink] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    // Fetch site info to get WhatsApp link
    const fetchSiteInfo = async () => {
      try {
        const response = await fetch('https://shristicooperative.com/api/site-info');
        const data = await response.json();
        setWhatsappLink(data.whatsapp_business_link);
      } catch (error) {
        console.error('Error fetching site info:', error);
      }
    };

    // Fetch product data
    const fetchDecorItems = async () => {
      try {
        const response = await fetch('https://shristicooperative.com/api/v1/products/category/jewellery');
        const data = await response.json();
        setDecorItems(data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchSiteInfo();
    fetchDecorItems();
  }, []);

  // Function to generate WhatsApp URL
  const generateWhatsappLink = (productId, productName) => {
    const message = `I want to enquire about Product ID: ${productId}, Name: ${productName} from madyantika.in.`;
    return `${whatsappLink}text=${encodeURIComponent(message)}`;
  };

  return (
    <div className='jewellery'>
      <h1>Jewellery</h1>
      <motion.div className="jewellery-container" initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1 }}>
        {decorItems.length > 0 ? (
          decorItems.map((item, index) => (
            <motion.div key={index} className="decor-item-card" initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1, delay: index * 0.1 }}>
              <img src={`https://shristicooperative.com/storage/app/public/products/${item.image}`} alt={item.name} className="decor-item-image" />
              <div className="decor-item-info">
                <h3>{item.name}</h3>
                <p>₹{item.price} /item</p>
                <p className='qty'>Minimum Order Quantity: {item.min_order_qty}</p>
              </div>
              <button 
                className="enquiry-btn" 
                onClick={() => window.location.href = generateWhatsappLink(item.id, item.name)}
              >
                <FaCartPlus /> Do Enquiry
              </button>
              <button className="details-btn" onClick={() => navigate(`/product/${item.id}`)}>
                <FaCartPlus /> View Details
              </button>
            </motion.div>
          ))
        ) : (
          <p>Loading items...</p>
        )}
      </motion.div>
    </div>
  );
};

export default Jewellery;
