import React from 'react'
import HeroSection from '../../components/herosection/HeroSection'
import Categories from '../../components/categories/Categories'
import OurStory from '../../components/ourstory/OurStory'
import Footer from '../../components/footer/Footer'
import MessageFromFounders from '../../components/messagefromfounders/MessageFromFounders'
import MissionVision from '../../components/missionvision/MissionVision'
import FollowUs from '../../components/socialmedia/FollowUs'
import Testimonials from '../../components/testimonials/Testimonials'

const HomePage = () => {
  return (
    <>
    <HeroSection/>
    <Categories/>
    <OurStory/>
    <MissionVision/>
    <FollowUs/>
    <MessageFromFounders/>
    <Testimonials/>
    <Footer/>
    </>
  )
}

export default HomePage